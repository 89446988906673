<template>
<div id="history">
  <b-field class="markdown-body">
    <help-history />
  </b-field>
  <div class="box">
    <b-field horizontal label="根据 typeid 筛选">
      <b-input v-model="historyType" placeholder="默认值 0 代表所有的 typeid"/>
    </b-field>
    <b-field horizontal label="根据 regional 筛选">
      <b-input v-model="historyRegional" placeholder="默认值 -1 代表所有的 regional"/>
    </b-field>
    <b-field horizontal label="根据 adminuid 筛选">
      <b-input v-model="historyAdminuid" placeholder="默认值 -1 代表所有的 adminuid" />
    </b-field>
    <b-field horizontal >
      <button class="button is-warning" :disabled="isLoading" @click="updateRecord">筛选</button>
    </b-field>
  </div>
  <hr />
  <b-table :data="histories"
        paginated backend-pagination
        :total="total"
        :per-page="perPage"
        :current-page="currentPage"
        detailed detail-key="hid"
        @page-change="onPageChange">
    <b-table-column #default="props" field="hid" label="hid" width="100">
      {{ props.row.hid }}
    </b-table-column>
    <b-table-column #default="props" field="adminuid" label="adminuid" width="100">
      {{ props.row.adminuid }}
    </b-table-column>
    <b-table-column #default="props" field="nickname" label="管理员昵称" width="180">
      {{ props.row.nickname }}
    </b-table-column>
    <b-table-column #default="props" field="status" label="status" width="100">
      {{ props.row.status }}
    </b-table-column>
    <b-table-column #default="props" field="r" label="regional" width="100">
      {{ props.row.r }}
    </b-table-column>
    <b-table-column #default="props" field="typeid" label="typeid" width="80">
      {{ props.row.typeid }}
    </b-table-column>
    <b-table-column #default="props" field="note" label="说明">
      <pre style="overflow-x:scroll;white-space:pre-wrap;"> {{ props.row.note }} </pre>
    </b-table-column>
    <b-table-column #default="props" field="createtime" label="操作时间" width="200">
      {{ formatDateTime(props.row.createtime) }}
    </b-table-column>
    <template #bottom-left>
      <b>总计：</b>{{ total }}
    </template>
    <template #detail="{row}">
      <p v-for="(v, k) in row" :key="k">{{k}}: {{v}}</p>
    </template>
  </b-table>
</div>
</template>

<script>
/**
 * 查看操作记录
 */
import config from '@/core/config'
import type from '@/core/type'
import HelpHistory from '@/help/history.md'

export default {
  name: 'history',
  components: { HelpHistory },
  mounted () {
    this.updateRecord()
  },
  data () {
    return {
      isLoading: false,
      historyType: 0,
      historyRegional: -1,
      historyAdminuid: -1,
      historyTypes: type.historyTypes(),
      perPage: config.PER_PAGE,
      currentPage: 1,
      histories: [],
      total: 0
    }
  },
  methods: {
    updateRecord () {
      this.currentPage = 1
      this.getRecords()
    },
    async getRecords (page) {
      page = page || this.currentPage || 1
      const r = this.historyRegional ? this.historyRegional : -1
      try {
        const filterobj = { page, per_page: this.perPage, r }
        if (this.historyType > 0) {
          filterobj.typeid = this.historyType
        }
        if (this.historyAdminuid > 0) {
          filterobj.adminuid = this.historyAdminuid
        }
        const rdata = await this.mjp.get2({
          url: '/admin/history/get/more/',
          query: filterobj
        })
        this.histories = rdata.histories
        this.total = rdata.total
        this.perPage = rdata.per_page
        this.page = rdata.page
      } catch (error) {
        console.error(error)
      }
    },
    onPageChange (page) {
      this.currentPage = page
      this.getRecords(page)
    }
  }
}
</script>

<style>
#history {
  padding: 12px;
}
</style>
